
import { Vue, Component } from "vue-property-decorator"
import { FetchResult } from "apollo-link"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import {
  MissionPerformanceAppraisalSchedulesQuery,
  MissionPerformanceAppraisalSchedulesQueryQuery,
  PerformanceAppraisalScheduleFragmentFragment,
} from "@/gql"
import FilterBar from "@/components/widgets/common/FilterBar.vue"

@Component({ components: { AppDetailPage } })
export default class AppraisalSchedule extends Vue {
  readonly query = MissionPerformanceAppraisalSchedulesQuery
  schedules: PerformanceAppraisalScheduleFragmentFragment[] = []
  filter: { [key: string]: any } = {}

  actions = {
    export: () => {
      this.exportData()
    },
  }

  readonly headers = [
    {
      text: "Appraisee",
      value: "appraisee.name",
      sortable: false,
      class: "",
    },
    {
      text: "Appraisee's Role",
      value: "appraiseeRole.name",
      sortable: false,
    },
    {
      text: "Appraiser",
      value: "appraiser.name",
      sortable: false,
    },
    {
      text: "Appraiser's Role",
      value: "appraiserRole.name",
      sortable: false,
    },
    {
      text: "Mission",
      value: "missionLifecycle.name",
      sortable: false,
    },
    {
      text: "Week Ending At",
      value: "createdAt",
      sortable: false,
      custom: true,
    },
  ]

  exportData() {
    return (
      this.$refs.filterBar &&
      (this.$refs.filterBar as FilterBar).export({
        query: this.query,
        queryKey: "missionPerformanceAppraisalSchedules",
        variables: {
          date: this.filter.createdAt,
        },
        headers: [...this.headers],
      })
    )
  }

  onResult(result: FetchResult<MissionPerformanceAppraisalSchedulesQueryQuery>) {
    if (
      result.data &&
      result.data.missionPerformanceAppraisalSchedules &&
      result.data.missionPerformanceAppraisalSchedules.data
    ) {
      this.schedules = result.data.missionPerformanceAppraisalSchedules.data
    }
  }

  created() {
    this.headers &&
      this.headers.forEach((h) => {
        h["class"] = "subtitle-2 font-weight-bold"
      })
  }
}
